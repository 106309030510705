import { FC, useRef } from 'react';
import { cx, LinariaClassName } from '@linaria/core';

import { useIsInViewport } from '@/hooks';

import * as styles from './styles';

interface Props {
  className?: LinariaClassName;
}

const ScrollBottom: FC<Props> = ({ className }) => {
  const ref = useRef(null);
  const visible = useIsInViewport(ref);

  return (
    <div
      {...{
        className: cx(
          styles.container,
          !visible ? styles.scrollButtonHidden : styles.scrollButtonVisible,
          className,
        ),
      }}
    >
      <span
        {...{
          ref,
          className: styles.scrollButtonAnchor,
        }}
      />

      <button
        {...{
          className: styles.button,
          type: 'button',
          onClick: () => {
            if (window && visible) {
              const top = window.innerHeight;

              window.scroll({
                behavior: 'smooth',
                left: 0,
                top,
              });
            }
          },
        }}
      >
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M16 11L12 15L8 11"
            stroke="#242933"
            strokeWidth="2"
            strokeMiterlimit="10"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </button>
    </div>
  );
};

export default ScrollBottom;
