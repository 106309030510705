import { FC, useEffect } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { cx } from '@linaria/core';

import TvLogosBlock from '@/components/tvLogosBlock';

import { creatHrefWithUTM } from '@/helpers/withUTM';

import Page from '@/layouts/mainPageNew/components/page';

import { MentorShort } from '@/service/api/models/homepage';
import { WithHomepagePreviewData } from '@/service/serverSideProps/getFeaturedMentors';

import Logos from './components/logos';
import MentorsSlider from './components/mentorsSlider';
import ScrollBottom from './components/scrollBottom';

import * as styles from './styles';

interface Props {
  mentors: WithHomepagePreviewData<MentorShort>[] | undefined;
}

const MainPageNewContainer: FC<Props> = ({ mentors }) => {
  const router = useRouter();

  useEffect(() => {
    window?.fbq?.('track', 'ViewContent');
  }, []);

  return (
    <>
      <Page>
        <picture
          {...{
            className: styles.creatorPhoto,
          }}
        >
          <source
            {...{
              src: '/main/creator_upper.jpg',
            }}
          />

          <img
            {...{
              src: '/main/creator_upper.webp',
              alt: '',
            }}
          />
        </picture>

        <div
          {...{
            className: styles.mainContent,
          }}
        >
          <Logos
            {...{
              className: styles.mainLogos,
            }}
          />

          <h1
            {...{
              className: styles.mainText,
            }}
          >
            Start Your Fitness Journey with
            <br />
            Your Ideal Wellness Partner
          </h1>

          <p
            {...{
              className: styles.mainDescription,
            }}
          >
            Begin with your Perfect Match, then explore and grow with access to
            hundreds of exceptional Fitness Creators. Embrace diversity and
            endless possibilities in your fitness adventure!
          </p>

          <div
            {...{
              className: styles.mainBottomButtons,
            }}
          >
            <Link
              {...{
                href: creatHrefWithUTM(router, '/mentors/quiz'),
              }}
            >
              <a
                {...{
                  className: cx(styles.actionButton, styles.linkButton),
                }}
              >
                Find your mentor
              </a>
            </Link>

            {!!mentors?.length && (
              <a
                {...{
                  className: cx(
                    styles.actionButton,
                    styles.linkButton,
                    styles.linkButtonWhite,
                  ),
                  href: creatHrefWithUTM(router, '#featured-creators'),
                }}
              >
                Featured Creators
              </a>
            )}
          </div>

          <TvLogosBlock
            {...{
              className: styles.tvLogos,
            }}
          />
        </div>

        {!!mentors?.length && (
          <ScrollBottom
            {...{
              className: styles.mainScrollDown,
            }}
          />
        )}
      </Page>

      {!!mentors?.length && (
        <Page
          {...{
            id: 'featured-creators',
          }}
        >
          <h2
            {...{
              className: styles.secondHeader,
            }}
          >
            featured creators
          </h2>

          <p
            {...{
              className: styles.secondDescription,
            }}
          >
            Meet fitolio&apos;s top creators, shaping the wellness landscape
            with their exclusive content. Join us, get inspired, and transform
            your fitness journey
          </p>

          <MentorsSlider
            {...{
              mentors,
            }}
          />
        </Page>
      )}
    </>
  );
};

export default MainPageNewContainer;
