import merge from 'lodash/merge';

import { PictureParams } from './types';

export const generateURL = (
  bucket: string,
  key: string,
  params?: PictureParams,
): string | null => {
  if (typeof window === 'undefined') {
    return null;
  }

  const { NEXT_PUBLIC_IMAGE_CDN } = process.env;

  if (typeof window !== undefined) {
    if (!NEXT_PUBLIC_IMAGE_CDN) {
      console.error('image cdn url is not defined');
      return null;
    }

    const pictureParams: PictureParams = merge({ edits: {} }, params);
    const { devicePixelRatio } = window;

    if (params?.edits?.resize?.width) {
      pictureParams.edits = {
        ...pictureParams.edits,
        resize: {
          ...pictureParams.edits?.resize,
          width: params.edits.resize.width * devicePixelRatio,
          fit: 'outside',
        },
      };
    }

    if (params?.edits?.resize?.height) {
      pictureParams.edits = {
        ...pictureParams.edits,
        resize: {
          ...pictureParams.edits?.resize,
          height: params.edits.resize.height * devicePixelRatio,
        },
      };
    }

    const result = { bucket, key, ...pictureParams };

    const requestParams = window.btoa(JSON.stringify(result));

    return `${NEXT_PUBLIC_IMAGE_CDN}/${requestParams}`;
  }

  return null;
};

export const setParamsWidthHeight = (
  width: number,
  height: number,
  params: PictureParams | undefined,
): PictureParams => {
  const defaults: PictureParams = {
    edits: {
      resize: {
        width,
        height,
      },
    },
  };

  if (!params) {
    return defaults;
  }

  return merge(params, defaults);
};
