import type { NextPage, GetServerSideProps } from 'next';
import Head from 'next/head';

import MainPageNewContainer from '@/containers/mainPageNew';

import MainPageNewLayout from '@/layouts/mainPageNew';
import { MentorShort } from '@/service/api/models/homepage';
import {
  getFeatureMentors,
  WithHomepagePreviewData,
} from '@/service/serverSideProps/getFeaturedMentors';

interface Props {
  mentors: WithHomepagePreviewData<MentorShort>[] | undefined;
}

const Page: NextPage<Props> = ({ mentors }) => (
  <>
    <Head>
      <title>fitolio.com</title>
    </Head>

    <MainPageNewLayout>
      <MainPageNewContainer
        {...{
          mentors,
        }}
      />
    </MainPageNewLayout>
  </>
);

export const getServerSideProps: GetServerSideProps = async () => {
  let mentors = null;

  try {
    mentors = await getFeatureMentors();
  } catch (e: unknown) {
    console.log(e);
  }

  return {
    props: {
      mentors,
    },
  };
};

export default Page;
