import { FC, useEffect, useState } from 'react';
import { Blurhash } from 'react-blurhash';
import classNames from 'classnames';

import { Image } from '@/service/api/models/homepage';

import { generateURL, setParamsWidthHeight } from './helpers';
import * as styles from './styles';
import { PictureParams } from './types';

interface Props {
  image?: Image;
  params?: PictureParams;
  title?: string;
  width: number;
  height: number;
  className?: string;
  imgClassName?: string;
  showBackground?: boolean;
}

const Picture: FC<Props> = ({
  image,
  title = '',
  width,
  height,
  params,
  showBackground = true,
  className,
  imgClassName,
}) => {
  const [imgUrl, setImgUrl] = useState<null | string>(null);

  useEffect(() => {
    if (!image?.key || !image.bucket || !image.link) {
      return;
    }

    const normalizedParams = setParamsWidthHeight(width, height, params);
    const url = generateURL(image.bucket, image.key, normalizedParams);

    if (!url) {
      setImgUrl(image.link);
      return;
    }

    const img = document.createElement('img');
    img.onload = () => {
      setImgUrl(url);
    };
    img.src = url;
  }, [image?.key, image?.bucket, image?.link]);

  if (!image) {
    return null;
  }

  return (
    <div
      {...{
        className: classNames(styles.picture, className),
        style: {
          width,
          height,
        },
      }}
    >
      {imgUrl && (
        <img
          {...{
            src: imgUrl,
            alt: title,
            className: classNames(styles.pictureImage, imgClassName),
          }}
        />
      )}

      {showBackground && image.blurhash && (
        <div
          {...{
            className: styles.blurredBg,
          }}
        >
          <Blurhash
            {...{
              hash: image.blurhash,
              width,
              height,
              style: {
                width: '100%',
                height: '100%',
                position: 'absolute',
                display: 'block',
              },
            }}
          />
        </div>
      )}
    </div>
  );
};

export default Picture;
